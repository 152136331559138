import React from "react";

import { Link } from "react-router-dom";
import { CrewComponent } from "../Components/CrewComponent";

import "./AboutPageContainer.css";

import crew1 from "../images/crew.jpg";
import antonia from "../images/antonia.png";
import ergo from "../images/ergo.jpg";

import cv1 from "../images/cv1.pdf";
import cv2 from "../images/cv2.pdf";

export class AboutPageContainer extends React.Component {
  render() {
    return (
      <div>
        <div className="container hidden-md-up">
          <div className="row">
            <div className="col-md-3">
              <img className="ship1" src={crew1} alt="ship1" />
            </div>

            <div className="col-md-3 text-left">
              <h3 className="mb-4">
                <strong>Meet the Crew</strong>
              </h3>
              <p>
                Our Crew are certified by our Flagship Crew and all have been
                endorsed by Yacht Master for their skipper and Divemaster
                qualifications. All our Stewards have received STCW 95 training
                and all crew have deck and food hygiene certificates also for
                serving and presentation.
              </p>
            </div>

            <div className="col-md-3">
              <CrewComponent name="Ergo" role="captain" profile={ergo}>
                Ergo is an experienced Olympic sailor from Estonia, with
                knowledge of English, Russian, Finnish, German, Spanish,
                Estonian.
                <br />
                <a className="btn btn-dark" href={cv1}>
                  CV
                </a>
              </CrewComponent>
            </div>
            <div className="col-md-3">
              <CrewComponent name="Antonia" role="stewardess" profile={antonia}>
                Antonia is a multi-lingual experienced hostestess with knowledge
                of Russian, English, Czech, Spanish
                <br />
                <a className="btn btn-dark" href={cv2}>
                  CV
                </a>
              </CrewComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutPageContainer;
