import React from "react";
import { Link } from "react-router-dom";

import "./CrewComponent.css";

export class CrewComponent extends React.Component {
  render() {
    return (
      <div className="crew-component text-center">
        <div className="crew-profile">
          <img
            className="crew-profile-img"
            src={this.props.profile}
            alt="profilepic"
          />
        </div>

        <div className="crew-content">
          {this.props.role}
          <h2>{this.props.name}</h2>
          <p>{this.props.children}</p>
        </div>
      </div>
    );
  }
}