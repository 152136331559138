import React from "react";

import { Link } from "react-router-dom";

import bg from "../images/bg1.jpg";
import bg2 from "../images/bg2.jpg";

import ship1 from "../images/ship1.jpg";
import ship2 from "../images/ship2.jpg";

import "./HomePageContainer.css";

export class HomePageContainer extends React.Component {
         constructor(props) {
           super(props);
           this.applyDiv = React.createRef();
           this.aboutDiv = React.createRef();
         }

         handleOnClickViewPositions = event => {
           if (this.applyDiv.current) {
             this.applyDiv.current.scrollIntoView({
               behavior: "smooth"
             });
           }
         };

         handleOnClickAbout = event => {
           if (this.aboutDiv.current) {
             this.aboutDiv.current.scrollIntoView({
               behavior: "smooth"
             });
           }
         };

         render() {
           return (
             <div>
               {/* <main class="wrapper">
                 <section class="section parallax bg1">
                   <h1>Such Adorableness</h1>
                 </section>
                 <section class="section static">
                   <h1>Boring</h1>
                 </section>
                 <section class="section parallax bg2">
                   <h1>SO FWUFFY AWWW</h1>
                 </section>
               </main> */}

               <div className="wrapper">
                 <div className="parallax">
                   <img src={bg} alt="BG" />
                 </div>
               </div>
               <div className="container section-div">
                 <div className="row align-items-center">
                   <div className="col-md-6">
                     <h1>Welcome to your floating mansion.</h1>
                     <p>
                       Aoibh (pronounced "Eve”, of Irish derivation meaning
                       poised and gracious) is a flagship class catamaran from
                       Fountaine Pajot. Dedicated to ocean cruising and perfect
                       family holidays, the Sanya 57 ship offers all the
                       pleasures of the sea and excellent comfort, beautiful
                       finishing touches and sea keeping qualities. Her crew has
                       a wealth of experience supported by formal
                       qualifications, high standards of attention to detail, as
                       vast sailor experience. We believe that the combination
                       of stunning destinations, a luxurious catamaran and our
                       dedicated crew are the perfect combination to make all
                       our guests’ experiences truly unforgettable.
                     </p>
                     <br></br>
                   </div>
                   <div className="col-md-6">
                     <img className="ship1" src={ship1} alt="ship1" />
                     <img className="ship2" src={ship2} alt="ship1" />
                   </div>
                   <div class="col-md-6 text-center">
                     <Link className="btn btn-primary" to="/about">
                       Meet the Crew
                     </Link>
                   </div>
                 </div>
               </div>

               <div className="parallax align-right">
                 <img src={bg2} alt="BG" />
               </div>

               <div className="container section-div">
                 <div className="align-items-center">
                   <br></br>
                   <br></br>
                   <h1 className="text-center">
                     Luxury in a Resilient Fortress{" "}
                   </h1>
                   <div className="row text-center ">
                     <div className="col-md-4">
                       <h2>Outdoor</h2>
                       <p>
                         Aoibh has superb outdoor living spaces in store for you
                         , to give rhythm to your cruise, whilst respecting your
                         comfort and the boat’s elegance. It’s new design allows
                         an enormous amount of outer deck space for guests and
                         like the saloon (but in open space) the upper
                         sunbathing area allows a 360 panoramic view all around
                         the yacht, fully enhancing the sailing experience. The
                         deck areas are spacious and with a secure and safe
                         access from the starboard side of the boat. The helm
                         station allows for total control of the yacht from one
                         point with two seats and external helm, but also coming
                         with a small roof/cover to reduce over-exposure to the
                         sun. To top it off the seating areas are spacious
                         enough to accommodate all guests and crew and a perfect
                         alternative for evening cocktails, before being called
                         down for dinner.
                       </p>
                     </div>

                     <div className="col-md-4">
                       <h2>Indoor</h2>
                       <p>
                         Aiobh is a Fountaine Pajot design that has been fitted
                         out as per the Owners vision, making sure every aspect
                         of the boat is at its peak and giving luxury to on
                         board guests. This version offers an owners suite on
                         the port side and three VIP Guest Cabins one being on
                         the same side as the Owners and the other two on the
                         starboard side. Crew accommodation is between the guest
                         cabins on the starboard side. The services and stores
                         areas to support the day to day operations can be found
                         in the forepeaks and include an on board washing
                         machine, enabling us to deal with any minor clothing
                         "emergencies” guests may have.
                       </p>
                     </div>

                     <div className="col-md-4">
                       <h2>Sail</h2>
                       <p>
                         When you're ready to cross the seas and see new
                         destinations, Aoibh is fitted with state of the line
                         sailing options. The powerful mainsail is aided by a
                         choice of Genaker or Genoa, all on rolling furlers to
                         provide maximum speed and efficiency under sail. For
                         when you need to cruise without wind and sails, Aoibh
                         is equipped with two 110hp diesel Volvo engines. The
                         performance characteristics of Fountaine Pajot boats
                         are extremely well known and never fail to please.
                         Words cannot do justice to this model designed yacht
                         when under sail and in charter use.
                       </p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           );
         }
       }
export default HomePageContainer;
